import { useCallback, useEffect, useState } from "react";
import { FacingMode } from "@leatcom/portal-sessions";
import { getContact, getIntegrationConfiguration, getLocationConfiguration } from "./api";
import { useLoading, WebExtensionProvider } from "./context";
import { GetCurrentAccountData, IntegrationConfiguration, LSKDiscount, Response } from "./types";
import { Router } from "./routes";
import { Error } from "components";
import LoadingOverlay from "react-loading-overlay-ts";

type AppProps = {
    apiKey: string;
};

export default function App({ apiKey }: AppProps) {
    const [configuration, setConfiguration] = useState<IntegrationConfiguration>({
        camera_service: 1,
        facing_mode: FacingMode.USER,
        enable_email_identification: true,
        business_profile_uuid: "",
        active: true,
    });
    const [discounts, setDiscounts] = useState<Array<LSKDiscount>>([]);
    const [contactUuid, setContactUuid] = useState<string>();
    const { loading } = useLoading();

    const setup = useCallback(async () => {
        window.pos_getCurrentAccount(async (response: Response<GetCurrentAccountData, any>) => {
            if (response.data.consumer && response.data.consumer.email) {
                const { data } = await getContact(response.data.consumer.email);
                setContactUuid(data.uuid);
            }
        });

        const { data: configuration } = await getIntegrationConfiguration(posContext.locationId);
        setConfiguration(configuration);

        const { data } = await getLocationConfiguration(posContext.locationId);
        setDiscounts(data.discounts);
    }, []);

    useEffect(() => {
        (async () => await setup())();
    }, [setup]);

    if (!configuration.active) {
        return <Error message={"This integration is currently inactive. Activate it to continue."}/>
    }

    return (
        <WebExtensionProvider
            apiKey={apiKey}
            configuration={configuration}
            discounts={discounts}
            contactUuid={contactUuid}
        >
            <LoadingOverlay active={loading} spinner text="Loading, do not close session...">
                <Router/>
            </LoadingOverlay>
        </WebExtensionProvider>
    );
}
